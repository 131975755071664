<template>
  <div>
    <v-card-title class='py-4'>
      <v-spacer />

      <v-text-field
        v-model='search'
        append-icon='mdi-magnify'
        :label='$t("SEARCH")'
        single-line
        hide-details
        clearable
      />
    </v-card-title>

    <v-data-table
      class='wd-100'
      multi-sort
      :search='search'
      :headers='notFoundHeaders'
      :items='notFoundList'
    >
      <template #item.createdAt='{ item }'>
        <span>
          {{ formattedDateAndTime(item.createdAt) }}
        </span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
  import { format, parseISO } from 'date-fns';
  import ptBr from 'date-fns/locale/pt-BR';

  export default {
    name: 'RecordReusageNotFoundTable',
    props: {
      notFoundList: {
        type: Array,
        default: () => [],
      },
    },
    data: function () {
      return {
        search: '',
        notFoundHeaders: [
          { text: this.$t('CPF'), value: 'cpf' },
          { text: this.$t('RENACH'), value: 'renach' },
          { text: this.$t('LAST_RENACH'), value: 'lastRenach' },
          { text: this.$t('DESCRIPTION'), value: 'description' },
          { text: this.$t('DATE'), value: 'createdAt' },
        ],
      };
    },
    methods: {
      formattedDateAndTime: function (value) {
        return value && format(parseISO(value), 'dd/MM/yyyy HH:mm:ss.SSS', { locale: ptBr });
      },
    },
  };
</script>
